import * as Sentry from "@sentry/react";
import { FC, PropsWithChildren } from "react";

export function initSentry() {
  Sentry.init({
    dsn: "https://7321f00d9ff6417d93db0d7d6ccbccfe@o4503958392995840.ingest.sentry.io/4505053960863744",
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    // Performance Monitoring
    tracesSampleRate: 0.1,
    // Session Replay
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    environment: process.env.NODE_ENV === "development" ? "dev" : "prod",
  });
}

const ErrorFallback = () => (
  <div className="crash-container">
    <p>
      Woops... això ha petat. Hem rebut un missatge per poder-ho arreglar el més
      aviat possible.
    </p>
    <button
      className="btn-link"
      onClick={() => {
        // Ens petem el localStorage per si hi ha algo guardat allà que fa que peti.
        localStorage.clear();
        window.location.reload();
      }}
    >
      Prem aqui per reiniciar
    </button>
  </div>
);

export const ErrorBoundary: FC<PropsWithChildren<{}>> = ({ children }) => (
  <Sentry.ErrorBoundary fallback={() => <ErrorFallback />}>
    {children}
  </Sentry.ErrorBoundary>
);

import { GridColumnIcon } from "@glideapps/glide-data-grid";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { SailorResults } from "../../types";
import { ResizableGrid, ResizableGridColumn } from "../ResizableGrid";
import "./ClassifGeneral.css";

interface Props {
  name: string;
  sailors: SailorResults[];
  races: Array<{ date: Date }>;
}

export const ClassifGeneral = ({ name, sailors, races }: Props) => {
  const maxScore = useMemo(
    () => sailors.map((s) => s.score).reduce((a, b) => Math.max(a, b), 0),
    [sailors]
  );
  const minScore = useMemo(
    () =>
      sailors
        .map((s) => s.score)
        .reduce((a, b) => Math.min(a, b), Number.POSITIVE_INFINITY),
    [sailors]
  );
  const scoreDiff = maxScore - minScore;

  const navigate = useNavigate();
  const columns = useMemo(() => {
    const baseColumns: ResizableGridColumn<SailorResults>[] = [
      {
        id: "pos",
        title: "Pos",
        getCellContent: (_, i) => i + 1 + ".",
        onCellClick: (row) => navigate("/perfil/" + row.id),
      },
      {
        id: "name",
        title: "Nom",
        getCellContent: (row) =>
          row.name ?? row.boat_name ?? row.sail_number ?? "",
        width: (scale) => Math.round(100 * scale),
      },
      {
        id: "category",
        title: "Ct",
        getCellContent: (row) => ({
          displayData: row.category || "?",
          data: row.category,
          themeOverride:
            row.category === "1a"
              ? {
                  textDark: "white",
                  bgCell: `hsl(0deg 0% 20%)`,
                }
              : {
                  textDark: "black",
                  bgCell: `hsl(0deg 0% 80%)`,
                },
          contentAlign: "center",
        }),
      },
      {
        id: "score",
        title: "Punts",
        getCellContent: (row) => ({
          displayData: row.score.toString(),
          data: row.score.toString(),
          themeOverride: {
            textDark: "white",
            bgCell: `hsl(0deg 80% ${
              50 - (50 * (row.score - minScore)) / scoreDiff
            }%)`,
          },
          contentAlign: "right",
        }),
      },
    ];

    const raceColumns = races.map(
      (race, i): ResizableGridColumn<SailorResults> => {
        return {
          id: `prova-${i}`,
          title: race.date.getDate() + "/" + (race.date.getMonth() + 1),
          width: (scale) => Math.round(60 * scale),
          getCellContent: (row) => {
            const result = row.results[i];
            const medalIndex = result.points - 1;
            const medal = positionToMedal[medalIndex] ?? "";
            const medalColor = positionToMedalColor[medalIndex] ?? "";

            const text = Number.isNaN(Number(result.position))
              ? result.position + " " + result.points
              : medal + result.points;

            const content = result.discarded ? `(${text})` : text;

            if (medal) {
              return {
                data: content,
                displayData: content,
                themeOverride: {
                  textDark: medalColor,
                },
                bold: true,
                textShadow: true,
                contentAlign: "right",
              };
            }

            return {
              data: content,
              displayData: content,
              contentAlign: "right",
              bold: !result.discarded,
              themeOverride: result.discarded
                ? {
                    textDark: "#444",
                  }
                : {},
            };
          },
          onHeaderClick: () => navigate(i.toString()),
          icon: GridColumnIcon.HeaderUri,
        };
      }
    );
    return [...baseColumns, ...raceColumns];
  }, [minScore, navigate, races, scoreDiff]);

  // Per calcular la mitjana de participants, podem comptar quantes putuacions estàn i dividir pel nombre de regates
  const totalScores = sailors
    .map((sailor) => sailor.results.filter((r) => r.position !== "DNC").length)
    .reduce((a, b) => a + b, 0);
  const participantAverage = totalScores / races.length;
  const participantAverageString = races.length
    ? Math.round(participantAverage).toString()
    : "N/A";

  return (
    <>
      <div className="header-general">
        <h1>{name}</h1>
        <div className="regata-info">
          <div>Part. Mitja: {participantAverageString}</div>
        </div>
      </div>
      <ResizableGrid
        className="classif-general"
        columns={columns}
        data={sailors}
        freezeColumns={2}
        pinchBounds={{
          min: 0.4,
          max: 4 / 3,
        }}
      />
    </>
  );
};

const positionToMedalColor = ["gold", "silver", "#cc8748"];

const positionToMedal = ["🥇", "🥈", "🥉"];

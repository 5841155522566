/* eslint-disable no-extend-native */
export function polyfill() {
  if (!Array.prototype.at) {
    Array.prototype.at = at;
  }
}

// https://github.com/tc39/proposal-relative-indexing-method#polyfill
function at<T>(this: Array<T>, n: number) {
  // ToInteger() abstract op
  n = Math.trunc(n) || 0;
  // Allow negative indexing from the end
  if (n < 0) n += this.length;
  // OOB access is guaranteed to return undefined
  if (n < 0 || n >= this.length) return undefined;
  // Otherwise, this is just normal property access
  return this[n];
}

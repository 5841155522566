import './BottomBar.css'
import { Link } from 'react-router-dom'
import {AiOutlineHome} from 'react-icons/ai'
import {BiSearch} from 'react-icons/bi'
import {MdLink} from 'react-icons/md'
import {BsCalendar4} from 'react-icons/bs'
import {AiOutlineForm} from 'react-icons/ai'
import { showToa } from '../features'

const BottomBar = () => {
  return (
    <div className='bottom-bar'>
      <Link className='tab' to="/"><div className="icon"><AiOutlineHome size={25} /></div></Link>
      <Link className='tab' to="/cerca"><div className="icon"><BiSearch size={25} /></div></Link>
      <Link className='tab' to="/links"><div className="icon"><MdLink size={25} /></div></Link>
      <Link className='tab' to="/calendar"><div className="icon"><BsCalendar4 size={25} /></div></Link>
      { showToa() ? <Link className='tab' to="/toa"><div className="icon"><AiOutlineForm size={25} /></div></Link> : null }
    </div>
  )
}

export default BottomBar